import request from '@/utils/request'


// 查询新-用户品牌列表
export function listBrand(query) {
  return request({
    url: '/cigarette/user-brand/list',
    method: 'get',
    params: query
  })
}
export function getBrandList(query) {
  return request({
    url: '/cigarette/user-brand/getBrandList',
    method: 'get',
    params: query
  })
}
// 查询新-用户品牌分页
export function pageBrand(query) {
  return request({
    url: '/cigarette/user-brand/page',
    method: 'get',
    params: query
  })
}

// 查询新-用户品牌详细
export function getBrand(data) {
  return request({
    url: '/cigarette/user-brand/detail',
    method: 'get',
    params: data
  })
}

// 新增新-用户品牌
export function addBrand(data) {
  return request({
    url: '/cigarette/user-brand/add',
    method: 'post',
    data: data
  })
}

// 修改新-用户品牌
export function updateBrand(data) {
  return request({
    url: '/cigarette/user-brand/edit',
    method: 'post',
    data: data
  })
}

// 删除新-用户品牌
export function delBrand(data) {
  return request({
    url: '/cigarette/user-brand/delete',
    method: 'post',
    data: data
  })
}
