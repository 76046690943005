<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="Curves Type" prop="userId">
                <a-select v-model="queryParam.type">
                  <a-select-option :key="1">
                    History Curves
                  </a-select-option>

                  <a-select-option :key="2">
                    Favorite Curves
                  </a-select-option>

                  <a-select-option :key="3">
                    Production Curves
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Curves Name" prop="name">
                <a-input v-model="queryParam.name" placeholder="" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Brand" prop="brandId">
                <!--                <a-input v-model="queryParam.skuId" placeholder="" allow-clear/>-->
                <a-select
                  placeholder="-Brand-"
                  style="width: 100%"
                  showSearch
                  v-model="queryParam.brandId"
                  optionFilterProp="title"
                >
                  <a-select-option v-for="item in brandListData" :key="item.id" :value="item.id" :title="item.name">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Sku" prop="skuId">
<!--                <a-input v-model="queryParam.skuId" placeholder="" allow-clear/>-->
                <a-select
                  placeholder="-Sku-"
                  style="width: 100%"
                  showSearch
                  v-model="queryParam.skuId"
                  optionFilterProp="title"
                >
                  <a-select-option v-for="item in brandSkuList" :key="item.id" :value="item.id" :title="item.name">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="Status" prop="status">
<!--                <a-input v-model="queryParam.skuId" placeholder="" allow-clear/>-->
                <a-select v-model="queryParam.status">
                  <a-select-option :key="1">Enable</a-select-option>
                  <a-select-option :key="0">Disable</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="曲线名称" prop="name">-->
<!--                <a-input v-model="queryParam.name" placeholder="请输入曲线名称" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">

<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="曲线描述" prop="description">-->
<!--                  <a-input v-model="queryParam.description" placeholder="请输入曲线描述" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="曲线详情" prop="notes">-->
<!--                  <a-input v-model="queryParam.notes" placeholder="请输入曲线详情" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="适用于" prop="availableFor">-->
<!--                  <a-input v-model="queryParam.availableFor" placeholder="请输入适用于" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->

<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="曲线数" prop="curvesQty">-->
<!--                  <a-input v-model="queryParam.curvesQty" placeholder="请输入曲线数" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />Search</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />Clear All</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['cigarette:skuCurves:add']">-->
<!--          <a-icon type="plus" />Add-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['cigarette:skuCurves:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['cigarette:skuCurves:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['cigarette:skuCurves:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <table-setting-->
<!--          :style="{float: 'right'}"-->
<!--          :table-size.sync="tableSize"-->
<!--          v-model="columns"-->
<!--          :refresh-loading="loading"-->
<!--          @refresh="getList" />-->
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <a-row :gutter="[16,16]">
        <a-col :span="6" v-for="(item,index) in list">
          <div class="goodsDateInfo" :style="[{
            background:item.status === 0 ? '#f8f8f8':'#fff'
          }]">
            <div class="goodsDateInfo-absolute">
              <a-icon type="eye" v-hasPermi="['cigarette:skuCurves:details']" v-if="item.curvesId" @click="$refs.createForm.details(item, undefined)" theme="twoTone" style="font-size: 28px;margin-left: 5px" />
            </div>
            <div class="goodsDateInfo-GoodsDate">
              <div class="goodsDateInfo-GoodsDate-flex">
                <div class="echartsData" style="width: 100%; height: 300px;" :id="'chart-' + item.id" ></div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-empty :description="false" style="margin-top: 100px;margin-bottom: 100px" v-if="list.length === 0" />
      <!-- 数据展示  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
<!--      <a-table-->
<!--        :loading="loading"-->
<!--        :size="tableSize"-->
<!--        rowKey="id"-->
<!--        :columns="columns"-->
<!--        :data-source="list"-->
<!--        :pagination="false"-->
<!--        :bordered="tableBordered"-->
<!--      >-->

<!--        <div slot="curves" slot-scope="text, record">-->
<!--          <a-descriptions title="" bordered :column="1">-->
<!--            <a-descriptions-item label="Curves Name">-->
<!--              {{ record.name }}-->
<!--            </a-descriptions-item>-->

<!--            <a-descriptions-item label="Curves Description">-->
<!--              {{ record.description }}-->
<!--            </a-descriptions-item>-->
<!--            <a-descriptions-item label="Be Available For">-->
<!--              {{record.skuName}}-->
<!--            </a-descriptions-item>-->
<!--            <a-descriptions-item label="Notes">-->
<!--              <div v-html="record.notes"></div>-->
<!--            </a-descriptions-item>-->
<!--            <a-descriptions-item label="Status">-->
<!--              {{record.status == 1 ? 'Enable' : 'Disable'}}-->
<!--            </a-descriptions-item>-->
<!--            <a-descriptions-item label="Chart">-->
<!--              <div class="echartsData" style="width: 100%; height: 300px;" :id="'chart-' + record.id" ></div>-->
<!--            </a-descriptions-item>-->

<!--          </a-descriptions>-->
<!--        </div>-->
<!--        <span slot="createTime" slot-scope="text, record">-->
<!--          {{ parseTime(record.createTime) }}-->
<!--        </span>-->
<!--        <span slot="operation" slot-scope="text, record">-->
<!--          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['cigarette:skuCurves:details']">-->
<!--            <a-icon type="eye" />Details-->
<!--          </a>-->
<!--        </span>-->
<!--      </a-table>-->
      <!-- 分页  -->
      <a-pagination
        class="ant-table-pagination"
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `${total} results`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageSkuCurves,listSkuCurves, delSkuCurves } from '@/api/cigarette/skuCurves'
import {listBrand} from '@/api/cigarette/brand'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import TagSelectOption from "@/components/TagSelect/TagSelectOption";
import Style from "@/views/cigarette/style";
import { listSku } from '@/api/cigarette/sku'
import * as echarts from 'echarts';
export default {
  name: 'SkuCurves',
  components: {
    Style,
    TagSelectOption,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type:1,
        userId: null,
        skuId: null,
        status: null,
        name: null,
        description: null,
        notes: null,
        availableFor: null,
        brandId: null,
        curvesQty: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '${comment}',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '用户id关联{zb_user.id}',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '规格id关联{zb_brand_sku.id}',
        //   dataIndex: 'skuId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '默认：1-开启 0-禁用',
        //   dataIndex: 'status',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '曲线名称',
        //   dataIndex: 'name',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '曲线描述',
        //   dataIndex: 'description',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '曲线详情',
        //   dataIndex: 'notes',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '适用于',
        //   dataIndex: 'availableFor',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '品牌id',
        //   dataIndex: 'brandId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '曲线数',
        //   dataIndex: 'curvesQty',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: 'Curves',
          dataIndex: 'curves',
          scopedSlots: { customRender: 'curves' },
          align: 'center'
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      brandSkuList:[],
      brandListData:[]
    }
  },
  filters: {
  },
  created () {
    this.queryParam.skuId = this.$route.query.skuId || '';
    if(this.queryParam.skuId) {
      this.queryParam.type = 3
    }
    this.getList();
    this.getBrandSkuList();
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    console.log('页面初始化')
    // console.log(this.list)

  },
  methods: {
    getBrandSkuList() {
      listSku({
        status:1
      }).then(res => {
        this.brandSkuList = res.data;
      })
      listBrand({
        status:1
      }).then(res => {
        this.brandListData = res.data;
      })
    },
    renderChart(item) {
      console.log('进来了');
      console.log(item);
      const chartDom = document.getElementById(`chart-${item.id}`);
      const myChart = echarts.init(chartDom);
      const option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          show: false, // 隐藏横轴
          data: ['1S', '2S', '3S', '4S', '5S', '6S', '7S','8S']
        },
        yAxis: {
          type: 'value',
          show: false // 隐藏横轴
        },
        series: [
          {
            data: [item.one, item.two, item.three, item.four, item.five, item.six, item.seven,item.eight],
            type: 'line',
            areaStyle: {},
            label: {
              show: true,
              position: 'top',
              formatter: '{c} w'
            },
          }
        ]
      };
      myChart.setOption(option);
    },
    /** 查询新-规格曲线列表 */
    getList () {
      this.loading = true
     pageSkuCurves(this.queryParam).then(response => {
        this.list = response.data.records;

        this.total = response.data.total
        this.loading = false;

       setTimeout(() => {
         this.list.forEach((item, index) => {
           this.renderChart(item);
         });
       },1000)
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type:1,
        userId: undefined,
        skuId: undefined,
        status: undefined,
        name: undefined,
        description: undefined,
        notes: undefined,
        availableFor: undefined,
        brandId: undefined,
        curvesQty: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delSkuCurves(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/goods-sku-curves/export', {
            ...that.queryParam
          }, `新-规格曲线_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
<style lang="less" scoped>
.echartsData {
  width: 100%;
  height: 300px;
}
.goodsDateAdd {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}
.goodsDateAdd:hover {
  border: 1px solid blue;

  .goodsDateAdd-icon {
    color:blue;
  }

  .goodsDateAdd-title {
    color: blue;
  }
}
.goodsDateInfo {
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  border-radius: 8px;
  flex-direction: column;
}

.goodsDateInfo-GoodsDate-flex-img {
  height: 240px;
  width: 40%;
}

.goodsDateInfo-GoodsDate-flex-img-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 240px;
  width: 90%;
}

.goodsDateInfo-GoodsDate-flex-img-flex-right {
  width: 55%;
  height: 240px;
  //background: #f8f8f8;
}
.goodsDateInfo-GoodsDate-flex-name {
  width: 80%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.goodsDateInfo-absolute {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  z-index: 2;
}
.goodsDateInfo-GoodsDate {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.goodsDateInfo-GoodsDate-flex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.goodsDateInfo-bottom {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  display: none;
}

.goodsDateInfo-bottom-flex {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}
.goodsDateInfo:hover {
  border: 1px solid blue;
  .goodsDateInfo-absolute {
    display: block;
  }

  .goodsDateInfo-bottom {
    display: block;
  }
}
</style>
